/* Individual item */
.bm-item {
  display: inline-block;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 36px !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
  height: 14% !important;
  width: 90%;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #13054C !important;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.0) !important;
}

.bm-menu-wrap {
  position: absolute;
  left: inherit;
  z-index: 1100;
  width: 350px;
  top: 0px;
  height: 100%;
  transition: all 0s ease 0s;
  right: 0px;
}

.logo label {
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img:nth-child(1) {
  width: 60px;
}

.logo img:nth-child(2) {
  width: 170px;
}

.header-desktop a,
.header-desktop label {
  margin: 0 0.9rem;
}

.dropdown-menu a {
  margin: 0;
}
/* .dropdown-menu a:hover {
  margin: 0;
  padding: 0;
} */

.header-desktop a {
  height: inherit;
  display: flex;
  align-items: center;
}

.header-desktop label {
  padding: 5px 15px;
  font-size: 1rem;
}

.header-desktop label:hover {
  cursor: pointer;
}

.header-desktop a:hover {
  height: inherit;
  display: flex;
  align-items: center;
  border-bottom: 6px solid #7c67f9;
  /* border-top-left-radius: 50px 6px; */
  /* border-top-right-radius: 50px 6px; */
  border-radius: 6px;
}

.burger-pwa {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 1200px) {

  .header-desktop a,
  .header-desktop label {
    margin: 0 0.4rem;
    text-align: center;
  }
}


@media (min-width: 992px) {
  .header-desktop {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
  }
}


@media (max-width: 575px) {
  .logo img:nth-child(1) {
    width: 40px;
    height: 35px;
  }

  .logo img:nth-child(2) {
    width: 110px;
  }
}