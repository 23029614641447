.balance-address-header #page-wrap {
    background-color: #371D6C;
    padding: 1rem 0;
    border-radius: 0;
}

.balance-address-header #page-wrap .col-md-4 .correccion a {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
}

.balance-address-header .balance-headers,
.balance-headers a {
    display: flex;
    align-items: center;
}

.balance-address-header .balance-img img {
    background: #7963E0;
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
}

.balance-address-header .AdressAndLogos .fa-regular.fa-clone {
    background: #cb37df;
}

.balance-address-header .AdressAndLogos .fa-solid.fa-share-nodes {
    background: #874af9;
}

.balance-address-header .AdressAndLogos .fa-solid.fa-share-nodes:hover,
.balance-address-header .AdressAndLogos .fa-regular.fa-clone:hover {
    background: #80808059;
    cursor: pointer;
}

.balance-address-header .AdressAndLogos label {
    color: #b5b5b5;
    font-weight: 600;
}

.balance-address-header .AdressAndLogos label:hover {
    text-decoration: underline;
    cursor: pointer;
}

.balance-address-header .AdressAndLogos i {
    padding: 6px;
    border-radius: 50%;
    font-size: 15px;
    margin: 0 5px;
}

.balance-address-header .AdressAndLogos div:nth-child(1) {
    display: flex;
    align-items: center;
    align-content: center;
}

.balance-address-header label {
    font-size: 14px;
}

@media (min-width: 768px) {

    .balance-address-header #page-wrap .col-md-4 {
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
}

@media (max-width: 767px) {

    .balance-address-header #page-wrap {
        padding: 0.5rem;
    }

    .balance-address-header .balance-headers,
    .balance-address-header .balance-headers a {
        display: block;
    }
}

@media (max-width: 575px) {
    .balance-address-header .balance-img img {
        margin-right: 0.2rem;
        padding: 5px;
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 463px) {
    .balance-address-header .AdressAndLogos label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 223px;
    }
}

@media (max-width: 348px) {
    .balance-address-header .correccion .col {
        padding: 0;
        justify-content: space-evenly;
    }
}