.title-nft{
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
}

.img-container{
    overflow: hidden;
    border-radius: 15px;
}

.img-container > div {
    width: 100%;
}

.img-container > div > svg {
    width: 1000px;
}



.send-nft {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100rem;
    padding: 1rem;
    padding: .5rem 3rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff48fa,#78e4ff, #59bc1c);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #13054C inset;
    font-weight: 500;
    font-style: italic;
    font-size: 1.5rem;
    /* width: 85%; */
    height: 40px;
    /*display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100rem;
    padding: 1rem;
    padding: .5rem 3rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff48fa,#78e4ff, #59bc1c);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #13054C inset;
    font-weight: 600;
    font-family: 'Roboto';
    font-size: 20px;
    width: 100%;
    height: 40px;
    font-style: normal;*/
}

.send-nft:hover {
box-shadow: none;
}

.address-wrapper{
    display: flex;
    flex-direction: row;
    border: 1px solid white;
    border-radius: 15px;
    width: 85%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: white;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.nft-detail .transferirNft {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    margin: auto 0;
}


.input-address{
    background-color: transparent;
    border: none !important;
    width: 85%;
    color: white; 
    padding: 0 10px 0 0px;
}

.input-address:focus{
    color: white;
    outline: none;
}

.input-address::placeholder{
    color: rgb(171, 171, 171);
    border: none;
}

.icon-camera{
   width: 25px;
   height: 25px;
}

.description-wrapper-details{
    width: 85%;
    margin-top: 1rem;
}

.scanner-send{
    width: 320px !important;
}

.fullscreen-video{
    object-fit: fill !important;
}



.img-container > div {
    width: 100% !important;
    height: 100% !important;
}

.img-container > div > video{
    object-fit: fill !important;
    
}

.share-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
}

.nft-detail{
    margin-top: 2rem;
}

.nft-detail .center-flex-bottom .description-size {
    width: 60%;
}

@media (max-width: 1200px) {
    .nft-detail .center-flex-bottom .description-size{
        width: 80%;
    }
}

@media (max-width: 991px) {
    .nft-detail .center-flex-bottom .description-size{
        width: 100%;
    }
}


@media (min-width: 768px) {
    .title-wrapper-desktop{
        display: flex !important;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
        margin-bottom: 0.5rem;
        align-items: flex-start;
    }

    .nftImage{
        width: 100% !important;
        max-height: 100%;
    }
}

@media (max-width: 767px) {
    .center-flex-bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nftImage{
        width: 100% !important;
        max-height: 50vh;
    }

    .share-wrapper {
        width: 85%;
    }

    .transferirNft {
        width: 85%;
    }

    .title-wrapper{
        display: flex !important;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        margin-bottom: 0.5rem;
    }
}