.footer-desktop{
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff48fa,#78e4ff, #59bc1c);
    height: 70px;
}

.footer-desktop.freeze-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
}

.footer-desktop h6{
    margin: 0.6rem 0;
    color: #7c67f9;
}

.footer-desktop p {
    margin-top: 0;
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
}

.footer-desktop .rights-reserved {
    padding: 0.7rem 0;
    text-align: center;
    border-top: 1px solid #4e4e4ec0;
    margin: 1.2rem;
    font-size: 0.8rem;
}

#footer-container{
    height: 70px !important;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-desktop img {
    margin: 0 2rem 0 0;
}

.background-footer{
    height: 100%;
    margin-top: 5px;
    background: #0F0441;
}

#rights-reserved{
    font-style: italic; 
}

.opacity-footer-text{
    opacity: 0.4;
}

.background-footer a:hover {
    text-decoration: underline !important;
}

/* @media (max-height: 920px) {
    .footer-desktop.freeze-footer{
        position: relative;
    }


} */
