
.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
    width: aut;
}

.circle{
    width: 40px;
    background: #7a67db;
    font-weight: 600;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.recent-destinations{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 15px;
}


.contact:last-child{
    justify-content: flex-start;
}

.contact:last-child .circle{
    background: #fb56f4;
    margin: auto;
}

.contact .circle:hover {
    transform: scale(1.04);
    -moz-transform: scale(1.04);
    -webkit-transform: scale(1.04);
    transition: all .5s;
    cursor: pointer;
}

.contact p {
    margin: -2.2px;
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px;
}

.contact p:nth-child(2),
.contact a p:nth-child(2) {
    margin-top: 0px;
}

.contact i{
    font-size: 18px;
}

.addContact{
    background-color: #7a67db !important;
}

@media (max-width: 500px) {
    .circle {
        width: 35px;
        height: 35px;
        font-size: 12px;
    }

    .contact p {
        font-size: 12px;
        max-width: 52px;
    }

    .contact i{
        font-size: 12px;
    }


}