.notif-container {
    position: relative;
    margin-right: 1rem;
  }
  
  .notif-number {
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    bottom: -7px;
    right: -7px;
    background-color: #ff0000;
    border-radius: 50%;
    padding: 0px 5px;
    color: #ffffff;
  }

  .notif-list {
    position: absolute;
    top: 70px;
    
    background-color: #ffffff;
    border: 1px solid #cccccc;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .notif-item {
    padding: 8px;
    border-bottom: 1px solid #cccccc;
    color: black;

  }


  @media (max-width: 768px) {
    .notif-list {
      top: 50px;
      right: 10%;
  }
}