.max-width-form label {
  color: rgb(120, 120, 120);
}

.MuiInputBase-root{
  color: white !important;
}

.MuiFormLabel-root{
  color: white !important;
}

.div-props{
  display: flex;
    align-content: flex-start;
    align-items: baseline;
    justify-content: space-around;
}

.form-inline{
  display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.wrong-network {
  z-index: 1250;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-color: #ff9800;
  padding: 5px;
}