.freeze-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.footer-opciones{
    /* height: 70px; */
    background: #0d032d;
    border-top: #5b32b7 2px solid;
    display: flex;
    
}

.footer-opciones > div {
    width: 30rem !important;
    margin: 6px auto 5px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-right: 1px solid #5c38b3;
}

.footer-opciones > div:last-child {
    border-right: 0px solid white !important;
}

.footer-opciones > div div div{
    cursor: pointer;
    width: fit-content;
    margin: auto;
}

.footer-opciones > div div{
    width: 100%;
}


.footer-opciones p{
    font-size: 13px;
    margin-bottom: 0rem;
}

.footer-opciones i{
    font-size: 20px;
}
