
.input-makeNft{
    color: #AD75FC !important;
}

.input-makeNft label{
    color: #AD75FC !important;
}

.MuiFilledInput-input{
    color:  #AD75FC  !important;
}

.send-button-admin{
        width: 25%;
        border-radius: 100rem !important;
        /* padding: 1rem !important; */
        /* padding: 0.5rem 3rem !important; */
        /* box-shadow: 0 0 6px 0 rgb(157 96 212 / 50%) !important; */
         border: solid 3px transparent !important; 
        background:  #7963E0 !important;
        color: white;
        /* box-shadow: 2px 1000px 1px #13054c inset !important; */
        font-weight: 650 !important;
        font-size: 15px !important;
        font-family: 'Roboto' !important;
}

.send-button-admin-active{
    width: 50%;
    border-radius: 100rem !important;
    /* padding: 1rem !important; */
    /* padding: 0.5rem 3rem !important; */
    /* box-shadow: 0 0 6px 0 rgb(157 96 212 / 50%) !important; */
     border: solid 3px transparent !important; 
    background:  #4ac469 !important;
    color: white;
    /* box-shadow: 2px 1000px 1px #13054c inset !important; */
    font-weight: 650 !important;
    font-size: 15px !important;
    font-family: 'Roboto' !important;
}

.send-button-admin-paused{
    width: 50%;
    border-radius: 100rem !important;
    /* padding: 1rem !important; */
    /* padding: 0.5rem 3rem !important; */
    /* box-shadow: 0 0 6px 0 rgb(157 96 212 / 50%) !important; */
     border: solid 3px transparent !important; 
    background:  #7b7b7b !important;
    color: white;
    /* box-shadow: 2px 1000px 1px #13054c inset !important; */
    font-weight: 650 !important;
    font-size: 15px !important;
    font-family: 'Roboto' !important;
}

.send-button-admin-edit{
    width: 50%;
    border-radius: 100rem !important;
    /* padding: 1rem !important; */
    /* padding: 0.5rem 3rem !important; */
    /* box-shadow: 0 0 6px 0 rgb(157 96 212 / 50%) !important; */
     border: solid 3px transparent !important; 
    background:  #7963E0 !important;
    color: white;
    /* box-shadow: 2px 1000px 1px #13054c inset !important; */
    font-weight: 650 !important;
    font-size: 15px !important;
    font-family: 'Roboto' !important;
}

.td-table-list{
    color: #5c4ba7 !important;
}