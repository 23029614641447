.form-control {
    background: transparent !important;
    color:white;
}

.form-control:focus {
    color:white;
}

iframe {
    display: none;
}

i {
    color: white;
    font-size: 24px;
}

.button-login{
    background: transparent;
    border: none;
}

.button-login span{
    color:white;
}

.button-login span:hover{
    text-decoration: underline;
}

.button-login:hover{
    cursor: pointer;
}

.other-login{
    display: flex;
    margin: 5px 0 15px;
}

.other-login i,
.other-login span{
    margin: 0 5px;
}

.other-login span{
    color: white;
    font-weight: 500;
}

.other-login i:hover{
    cursor: pointer;
}

.other-login span:hover,
.remember-forgot span:hover{
    text-decoration: underline;
    cursor: pointer;
}

.form-width {
    width: 300px;
    margin: auto;
    text-align: center;
}



.remember-forgot{
    display: flex;
    justify-content: space-between;
    margin: 0.2rem 0 1rem;
    color: white;
}

.login-btn{
    background: #8744DE !important;
    padding: 10px 8px !important;
   
    font-size: 1.5rem !important;
    letter-spacing: 15px;
    border: 0px solid !important;
}

.login-btn:focus {     
    background-color:#4e2682 !important;
    border: 0px solid !important;
    transition: background-color 1s;    
}

.list-ul{
    list-style-type: none;
    padding: 0;
    margin-top: 1rem;
}

.li-login{
    color: #D7FF73;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
}

.check-login{
    color: #D7FF73;
}

.login-footer{
    align-items: center;
}

.row-login div{
    text-align: center;
}

@media (min-width: 992px) {
    
    .login.centrar-todo{
        height: 80vh;
    }
    
    .row-login div:nth-child(1){
        text-align: end;
    }

    .row-login div:nth-child(2){
        text-align: start;
    }
}

@media (max-width: 767px) {
    .tamaño-imagenes img{
        max-width: 20rem;
    }

    .send-button.btn span span {
        height: 8px !important;
        width: 8px !important;
    } 
}

@media (max-width: 575px) {
    .tamaño-imagenes img{
        width: 250px;
    }
}

@media (max-width: 414px) {
    .tamaño-imagenes img{
        width: 150px;
    }
}

/* @media (max-height: 485px) {
    .tamaño-imagenes img{
        width: 150px;
    }
} */

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .img-login{
        max-height: 30vh;
        width: auto;
    }

}