.fullscreen-video video{
    object-fit: cover;
}

.loaderWrapper{
    display: flex;
    justify-content: center;
    min-height: 170px;
    align-items: center;
}



/* @media only screen and (min-width: 768px) {
   
    .div-card-img{
        vertical-align: inherit;
        max-width: 100%;
        min-height: 0px;
        min-width: 0px;
        flex-shrink: 0;
        flex-direction: column;
        flex-basis: auto;
        display: flex;
        -webkit-box-align: stretch;
        align-items: stretch;
    } 
    
  }*/