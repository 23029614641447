.total-contact{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ffffff8a;
    margin: 12px 0 0;
    padding-bottom: 8px;
    justify-content: space-between;
}

.total-contact .first-part-contact{
    display: flex;
    align-items: center;
}

.total-contact .circle{
    width: 60px;
    height: 60px;
    font-size: 20px;
}

.total-contact .name-address{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}

.total-contact .name-address p {
    margin-bottom: 0;
}

.total-contact .name-address p:nth-child(1) {
    font-size: 20px;
}

.total-contact .name-address p:nth-child(2) {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 370px;
}

.total-contact .name-address p:nth-child(2) span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.total-contact:last-child {
    border-bottom: 0px solid #ffffff8a;
}

.trash-edit{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.trash-edit i{
    padding: 6px;
    border-radius: 50%;
    font-size: 15px;
    margin: 3px 0;
    background: #cb37df;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyIcon{
    padding: 6px;
    border-radius: 50%;
    font-size: 15px;
    margin: 0 5px;
    background: #cb37df;
}

.copyIcon:hover,
.trash-edit i:hover{
    background: #80808059;
    cursor: pointer;
}

.addFirstContact{
    width: 6rem !important;
    height: 6rem !important;
    font-size: 20px;
    background: #cb37df !important;
}

.no-contacts{
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.img-add-contact{
    width: 4rem;
}

@media (max-width: 500px) {
    .total-contact .name-address p:nth-child(2) {
        font-size: 14px;
        max-width: 200px;
    }
}