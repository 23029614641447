.email-transaccion-2 {
  margin: 1rem 0rem 0.5rem 0rem;
  display: flex;
  align-items: center;
}

.img-container-email {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-email {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container-email {
    max-width: 940px;
  }
  .img-container-email {
    margin: 1rem 5rem;
  }
}

.subject-email-window {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.sender-email-window {
  color: #9888E0;
  font-size: 16px;
  font-weight: bold;
}

.link-archive {
  color: rgb(181, 181, 181);
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
}

.link-archive:hover {
  text-decoration: underline !important;
  color: #9888E0 !important;
}