.container-landing{
    max-width: 80%;
    margin-bottom: 2rem;
}

.icon-text{
    color: white;
    font-weight: bold;
    text-align: center;
}

.icon-text-info{
    font-size: 12px;
}

.icon-wrapper{
    background-color: #BB6DF3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-bottom: 1rem;
}

.icon-landing{
    width: 4rem;
    height: 4rem;
}

.purple-back{
    background-color: #9470AC;
    display: flex;
    padding: 2rem 0rem;
    justify-content: space-evenly;
}

.purple-text{
    color: #12054D;
    font-size: 20px;
    font-weight: bold;
}

.log-button {
    border-radius: 100rem;
    padding: 0.3rem 2rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff48fa,#78e4ff, #59bc1c);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #9470AC inset;
    font-weight: bold;
    font-family: 'Roboto';
    font-size: 1rem;
    color: #12054D;
}

.log-button:hover {
box-shadow: none;
}

.landing-share{
    width: 100% !important;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .purple-text{
        color: #12054D;
        font-size: 36px;
        font-weight: bold;
        
    }

    .render-login{
        max-height: 60vh;
    }
  }