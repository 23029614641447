.react-datepicker__day--selected{
    background-color: #7963E0 !important;
}

.react-datepicker-ignore-onclickoutside{
    color: #7963E0 !important;
} 

.react-datepicker__input-container input{
    color: #7963E0;
}