.img-box-row  {
    margin-top: 1rem;
}

.img-box-row .col-md-4 {
    margin: 1rem 0;
}

.img-box-row .col-md-4 label {
    width: 100%;
}

.img-box-row .col-md-4 a {
    display: flex;
    justify-content: center;
    cursor: auto;
}

.img-box-row .col-md-4 label {
    display: flex;
    justify-content: center;
}

.img-box-row .col-md-7 {
    display: flex;
    align-items: center;
}

.img-box-row .col-md-5 img {
    max-width: 370px;
}

.Box {
    width: 170px;
    height: 170px;
    background: linear-gradient(180deg, rgba(63, 27, 131, 1) 35%, rgba(35, 14, 106, 1) 90%);
    border-radius: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 17px rgb(0 0 0 / 37%);
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.Box:hover {
    transform: scale(1.04);
    -moz-transform: scale(1.04);
    -webkit-transform: scale(1.04);
    transition: all .5s;
    cursor: pointer;
}

.Box label {
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
}

#home-headers #page-wrap {
    background-color: #371D6C;
    padding: 2.5rem 0;
    border-radius: 0 0 30px 30px;
}



#home-headers #page-wrap .col-md-4 .correccion a {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
}

.balance-headers, .balance-headers a{
    display: flex;
    align-items: center;
} 

.balance-img img {
    background: #7963E0;
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
}

/* #Home {
    min-height: calc(100vh - 66px);
    margin-bottom: 66px
} */

.AdressAndLogos .fa-regular.fa-clone {
    background: #cb37df;
}

.AdressAndLogos .fa-solid.fa-share-nodes {
    background: #874af9;
}

.AdressAndLogos .fa-solid.fa-share-nodes:hover,
.AdressAndLogos .fa-regular.fa-clone:hover {
    background: #80808059;
    cursor: pointer;
}

.AdressAndLogos label {
    color: #b5b5b5;
    font-weight: 600;
}

.AdressAndLogos label:hover {
    text-decoration: underline;
    cursor: pointer;
}

.AdressAndLogos i {
    padding: 6px;
    border-radius: 50%;
    font-size: 15px;
    margin: 0 5px;
}

.AdressAndLogos div:nth-child(1) {
    display: flex;
    align-items: center;
    align-content: center;
}

@media (max-width: 1200px) {
    .Box {
        width: 140px;
        height: 140px;
    }
    .img-box-row .col-md-5 img {
        max-width: 250px;
    }
}

@media (max-width: 991px) {
    .img-box-row .col-md-5 img {
        max-width: 250px;
    }

   
}

@media (min-width: 768px) {

    #Home .balance-address-header #page-wrap{
        border-radius: 0 0 30px 30px  !important;
        padding: 0.5rem 0 !important;
    }

    .img-box-row .col-md-5,
    #home-headers #page-wrap .col-md-4 {
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }

}

@media (max-width: 767px) {
    .Box {
        width: 170px;
        height: 170px;
    }

    #Home #page-wrap {
        padding: 0.5rem;
    }

    .balance-headers, .balance-headers a{
        display: block;
    } 
}

@media (max-width: 575px) {



}

@media (max-width: 463px) {
    .AdressAndLogos label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 223px;
    }

    .Box {
        width: 150px;
        height: 150px;
    }

    .Box label {
        font-size: 0.9rem;
    }
}

@media (max-width: 348px) {
    .Box {
        width: 135px;
        height: 135px;
    }

    .Box label {
        font-size: 0.8rem;
    }

    .correccion .col {
        padding: 0;
        justify-content: space-evenly;
    }
}