
.accept-terms-button {
    border-radius: 100rem;
    padding: 1rem;
    padding: .5rem 3rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff48fa,#78e4ff, #59bc1c) !important;
    background-origin: border-box;
    background-clip: content-box, border-box;
/*     box-shadow: 2px 1000px 1px #13054C inset;
 */    font-weight: 500;
    font-family: 'Roboto';
    font-size: 1.5rem;
}

.accept-terms-button:disabled{
    width: 100%;
    border-radius: 100rem !important;
    padding: 0.6rem 3.1rem;
    background: linear-gradient(270deg, #cecece 0%, #787878 22.97%, #303030 51.93%, #767676 83.85%, #ffffff 100%) !important;
    box-shadow: 2px 1000px 1px #7b7b7b inset !important;
    font-weight: 650 !important;
    font-size: 20px !important;
    font-family: 'Roboto' !important;
    margin-bottom: 5rem;
}

.torta-first{
    width: 100%;
}

.logo-first{
    width: 100%;
}

.row-header-landing{
    margin-right: 2rem;
    margin-left: 2rem;
    margin-top: 1rem;
    display: flex;
    align-items: start;
}

.landing-img{
    width: 100%;
}


@media only screen and (min-width: 768px) {
    /* For desktop: */
    .row-header-landing{
        align-items: center;
    }

    .col-torta{
        text-align: right;
    }
    .col-logo{
        text-align: center;
    }

    .torta-first{
        width: 200px;
       
    }
    
    .logo-first{
        width: 450px;
    }

    .landing-img{
        max-height: 40vh;
        width: auto !important;
    }
  }