.camaraNFT-video{
    display: flex;
}

.camaraNFT div:nth-child(1),
.camaraNFT div:nth-child(1) > div {
    position: relative;
}

.camaraNFT .camaraNFT-video > div:nth-child(1) div {
    height: 81.8vh;
}

.camaraNFT .camaraNFT-video > div:nth-child(2) {
    min-height: 81.8vh;
    width: 440px;
    padding: 0;
    position: relative;
    text-align: center;
}

.camaraNFT .camaraNFT-video > div:nth-child(2) form button ,
.camaraNFT .camaraNFT-video > div:nth-child(2)  input#file-upload-button {
    border-radius: 100rem;
    padding: 1rem;
    padding: 0.5rem 3rem;
    box-shadow: 0 0 6px 0 rgb(157 96 212 / 50%);
    border: solid 3px transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    font-weight: 500;
    font-style: italic;
    font-size: 1.5rem;
    margin-top: 2rem;
    background-color: #B5B5B5;
} 

.upload{
    width: 100%;
    border-radius: 100rem;
    padding: 1rem;
    padding: 0.5rem 3rem;
    font-weight: 500;
    font-style: italic;
    font-size: 1.5rem;
    margin-top: 2rem;
    background: linear-gradient(270deg, #B0F65B 0%, #01C6C5 22.97%, #4A7FF3 51.93%, #8171F5 83.85%, #B66DF3 100%);
    color: white;

}

.upload:disabled{
    background: #B5B5B5 !important;
    color: #13044B;
   
}

.camaraNFT div .camaraNFT-video > div:nth-child(2) form {
    text-align: center;
}

.div-buttons{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
}

@media (max-width: 1200px) {
    .camaraNFT .camaraNFT-video > div:nth-child(2){
        width: 50%;
    }
}

@media (max-width: 991px) {
    .camaraNFT-video {
        flex-direction: column;
    }
    .camaraNFT div:nth-child(1), .camaraNFT div:nth-child(1) > div,
    .camaraNFT div:nth-child(1), .camaraNFT div:nth-child(1) > div video {
        max-height: 69.5vh;
    }
    .camaraNFT .camaraNFT-video > div:nth-child(2) form button, .camaraNFT .camaraNFT-video > div:nth-child(2) form input#file-upload-button {
        margin-top: 0rem;
    }
    .camaraNFT .camaraNFT-video > div:nth-child(2) {
        width: 100%;
        min-height: 10vh;
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: row;
    }
    .camaraNFT .camaraNFT-video > div:nth-child(2)form {
        display: flex;
        align-items: center;
        align-content: center;
    }
}

@media (max-width: 824px) {
    .camaraNFT .camaraNFT-video > div:nth-child(2) form button, .camaraNFT .camaraNFT-video > div:nth-child(2) form input#file-upload-button{
        margin-top: 1rem;
    }
}

@media (max-width: 666px) {
    .camaraNFT .camaraNFT-video > div:nth-child(2),
    .camaraNFT .camaraNFT-video > div:nth-child(2) form{
        flex-direction: column !important;
        display: flex;
    }
    .camaraNFT .camaraNFT-video > div:nth-child(2) form{
        max-width: 260px;
    }
    .camaraNFT div:nth-child(1), .camaraNFT div:nth-child(1) > div, .camaraNFT div:nth-child(1), .camaraNFT div:nth-child(1) > div video {
        max-height: 43.5vh;
    }

    .div-buttons{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        height: 100%;
        justify-content: space-evenly;
    }
    
}

@media (max-width: 320px) {

}
@media (max-width: 320px) {

}
@media (max-width: 320px) {

}
@media (max-width: 320px) {

}