.goBack{
    background: #493c92;
    padding: 8px 0;
    /* min-height: 54px; */
    border-radius: 0 0 25px 25px;
    z-index: 99;
    position: relative;
}

.goBack i {
    margin-right: 15px;
    margin-left: 10px;
}

.goBack i:hover {
    cursor: pointer;
}

.goBack .container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.goBack h5 {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    margin: 0;
    font-weight: 200;
    font-size: 18px;
}

.goBack h5:hover {
    text-decoration: underline;
    cursor: pointer;
}