.balance-header{
    height: 18vh;
    background: #372069;
    margin-top: -20px;
    z-index: 0;
    padding-top: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 30px 30px;
    margin-bottom: 15px;
}

.balance-header .align-items-center{
    flex-direction: column !important;
}

.balance-header .correccion {
    width: 100%;
}

.balance-header img {
    width: 70%;
    height: 70%;
}

hr{
    background: linear-gradient(270deg, #B0F65B 0%, #01C6C5 22.97%, #4A7FF3 51.93%, #8171F5 83.85%, #B66DF3 100%);
    height: 2px;
    opacity: 1;
    margin: 0;
    color: transparent;
}

.transacciones > div:nth-child(1){
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    align-items: center;
}

.transacciones .transaccion:nth-child(odd){
    background: #372366;
    padding: 20px;
}

.transacciones .transaccion{
    padding: 20px;
}

.transacciones .transaccion > div{
    display: flex;
    justify-content: space-between;
}

.transacciones .transaccion > div div > span{
    font-size: 0.9rem;
    color: #b3adad;
    font-weight: 100;
} 

.transacciones .transaccion div > span{
    font-weight: 600;
}

.transacciones h5 {
    margin: 0;
}

.wordbreak{
    word-break: break-all !important; 
}