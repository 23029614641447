.title-nft{
    text-align: center;
    font-size: 1rem;
}

.img-container{
    overflow: hidden;
    border-radius: 15px;
    max-width: 87%;
}

.send-nft {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100rem;
    padding: 1rem;
    padding: .5rem 3rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff48fa,#78e4ff, #59bc1c);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #13054C inset;
    font-weight: 600;
    font-family: 'Roboto';
    font-size: 20px;
    width: 100%;
    height: 40px;
    font-style: normal;
}

.send-nft:hover {
box-shadow: none;
}

.tab-div{
    width: 75%;
}

.address-wrapper{
    display: flex;
    flex-direction: row;
    border: 1px solid white;
    border-radius: 15px;
    width: 85%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: white;
    height: 40px;
    align-items: center;
    justify-content: center;
}
.transferirNft {
    width: 85%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    margin: auto;
}


.input-address{
    background-color: transparent;
    border: none !important;
    width: 85%;
    color: white; 
    padding: 0 10px 0 0px;
}

.input-address:focus{
    color: white;
    outline: none;
}

.input-address::placeholder{
    color: rgb(171, 171, 171);
    border: none;
}

.icon-camera{
   width: 25px;
   height: 25px;
}

.description-wrapper{
    width: 85%;
    margin-top: 1rem;
    
}

.scanner-send{
    width: 320px !important;
}

.titulo-propiedades{
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}

.box-propiedades{
    text-align: center;
    font-size: 14px;
}

.send-button-landing{
    width: 100%;
    border-radius: 100rem !important;
    /* padding: 1rem !important; */
    /* padding: 0.5rem 3rem !important; */
    /* box-shadow: 0 0 6px 0 rgb(157 96 212 / 50%) !important; */
    /* border: solid 3px transparent !important; */
    background: linear-gradient(270deg, #B0F65B 0%, #01C6C5 22.97%, #4A7FF3 51.93%, #8171F5 83.85%, #B66DF3 100%) !important;
    /* box-shadow: 2px 1000px 1px #13054c inset !important; */
    font-weight: 650 !important;
    font-size: 20px !important;
    font-family: 'Roboto' !important;
    margin-bottom: 5rem;
}

.send-button-landing-2{
    /*width: 50%;*/
    border-radius: 100rem !important;
    /* padding: 1rem !important; */
    /* padding: 0.5rem 3rem !important; */
    /* box-shadow: 0 0 6px 0 rgb(157 96 212 / 50%) !important; */
    /* border: solid 3px transparent !important; */
    background: linear-gradient(270deg, #B0F65B 0%, #01C6C5 22.97%, #4A7FF3 51.93%, #8171F5 83.85%, #B66DF3 100%) !important;
    /* box-shadow: 2px 1000px 1px #13054c inset !important; */
    font-weight: 650 !important;
    font-size: 15px !important;
    font-family: 'Roboto' !important;
}