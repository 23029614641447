.add-funds{
    display: flex;
    height: 73.6vh;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
    flex-direction: column;
    color: black;
}

.add-funds button {
    border-radius: 100rem;
    padding: 1rem;
    padding: 0.5rem 3rem;
    box-shadow: 0 0 6px 0 rgb(157 96 212 / 50%);
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #ff48fa,#78e4ff, #59bc1c);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #13054c inset;
    font-weight: 500;
    font-style: italic;
    font-size: 1.5rem;
    color: white;
}

.iframe-buycripto{
    display: block !important;
    margin-top: 2rem;
    margin-bottom: 2rem;
}


@media (max-width: 500px) {
    .add-funds{
        margin: 10px 0 15px 0;
        height: 76vh;
        border-radius: 100rem !important;
    }

    .add-funds button{
        margin: 10px 0;
    }

}