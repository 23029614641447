
/* Individual item */
.bm-item {
    display: inline-block;
    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }
  
  /* Change color on hover */
  .bm-item:hover {
    color: white;
  }
  
  /* The rest copied directly from react-burger-menu docs */
  
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ffffff;
    height: 14% !important;
    width: 90%;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #13054C !important;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .bm-menu-wrap{
    position: absolute;
    left: inherit;
    z-index: 1100;
    width: 350px;
    top: 0px;
    height: 100%;
    transition: all 0s ease 0s;
    right: 0px;
  }
  
  .logo label{
    cursor: pointer;
  }

  .burger-pwa{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }