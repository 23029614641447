.mis-nft .col {
    text-align: center;
    margin: 20px auto;
}

.row-cards {
    margin-top: 1rem !important;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-cards div .hover-up-nft:hover{
    transform: scale(1.04);
    -moz-transform: scale(1.04);
    -webkit-transform: scale(1.04);
    transition: all .5s;
    cursor: pointer;
}

.mis-nft .col img {
    min-height: 150px;
    min-width: 100%;
    max-height: 150px;
}

.img-nft {
    width: 100%;
}

.div-nfts {
    border: 1px solid black;
    border-radius: 15px;
    overflow: hidden;
    background-color: #2A0173;
    margin: 10px 1.5rem;
    box-shadow: 0 3px 3px 1px rgb(0, 0, 0, 0.4);
}

.nft-name {
    text-align: center;
    width: 100%;
    margin: 0.5rem 0rem;
    font-weight: 700;
}

.MuiPagination-root {
    color: white !important;
    text-align: center;
    display: flex;
    justify-content: center;
}

.MuiButtonBase-root {
    color: white !important;
}

.css-1v2lvtn-MuiPaginationItem-root {
    color: white !important;
}

.css-o69gx8-MuiCardMedia-root{
    min-height: 200px !important;
}

@media (max-width: 1200px) {
    .mis-nft{
        min-height: calc(94vh - 0px);
    }
}

@media (max-width: 575px) {
    .css-19micn4-MuiButtonBase-root-MuiPaginationItem-root {
        min-width: 26px !important;
        height: 26px !important;
    }

    .css-o69gx8-MuiCardMedia-root {
        height: 150px !important;
        min-height: 150px !important;
    }
}