* {
    box-sizing: border-box;
  }
  
  .card {
    margin: 5px;
    width: 300px;
    height: 300px;
  }
  
  .card img {
    width: 100%;
    height: 100%;
  }
  
  .card > .card-body {
    height: 100%;
    overflow: auto;
  }
  