.Box-send{
    width: 20vh;
    height: 20vh;
    background: linear-gradient(180deg, rgba(63,27,131,1) 35%, rgba(35,14,106,1) 90%);
    border-radius: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 17px rgb(0 0 0 / 37%);
    margin: 1rem auto;
}

.Box-send:hover{
    transform: scale(1.04);
    -moz-transform: scale(1.04);
    -webkit-transform: scale(1.04);
    transition: all .5s;
    cursor: pointer;
}

.container-send{
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.title-with-gradient-underline-send{
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
    padding: 1rem;
  }
  
  .title-with-gradient-underline-send span {
    display: inline;
    text-decoration: none;
    background-image: linear-gradient(transparent, transparent),linear-gradient(transparent, transparent),linear-gradient(270deg, #B0F65B 0%, #01C6C5 22.97%, #4A7FF3 51.93%, #8171F5 83.85%, #B66DF3 100%);
    background-repeat: no-repeat;
    background-position: 120%, 122%, 0 130%;
    background-size: 100% 10px;
    padding-bottom: 10px;
  }

  @media (max-width: 463px) {
    .Box-send{
        width: 20vh;
        height: 20vh;
        background: linear-gradient(180deg, rgba(63,27,131,1) 35%, rgba(35,14,106,1) 90%);
        border-radius: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 17px rgb(0 0 0 / 37%);
        margin-bottom: 1rem;
    }

    .title-with-gradient-underline-send {
        font-size: 1.2rem;
        margin: 0px auto;
    }
}